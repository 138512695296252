import React, { useEffect, useState } from "react";
import EditIcon from "../../../../components/icons/edit";
import Plusicon from "../../../../components/icons/plus-icon";
import Minusicon from "../../../../components/icons/minus-icon";
import { PropertyType } from "../../../../redux/types";
import {
  fetchSingleProperty,
  updateProperty,
} from "../../../../redux/actions/property";
import { useDispatch } from "react-redux";
import DropdownTwo from "../../../../components/custom/drop-down-two";
import { TextInput } from "../../../../components/custom/text-input";
import CheckRedIcon from "../../../../components/icons/check-red";
import { timezone2 } from "../../../../utils/static-data";
import DropdownSearch from "../../../../components/custom/drop-down-search";

interface AboutPersonalProps {
  activeListing: PropertyType;
}

const AboutPersonal: React.FC<AboutPersonalProps> = ({ activeListing }) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [guestCount, setGuestCount] = useState(activeListing?.guests);
  const [ruletxt, setRuletxt] = useState("");
  const [personalRules, setPersonalRules] = useState<string[]>(
    activeListing?.rules
  );
  const [selectedTimezone, setSelectedTimezone] = useState(
    activeListing?.timezone
  );

  const handleSaveClick = async () => {
    const textRules = ruletxt.split(",");
    const allRules =
      ruletxt.length > 2
        ? [...personalRules, ...textRules]
        : [...personalRules];
    const res = await dispatch(
      // @ts-ignore
      updateProperty({
        id: activeListing?.id,
        rules: allRules,
        guests: guestCount,
        timezone: selectedTimezone,
      })
    );

    if (res) {
      // @ts-ignore
      dispatch(fetchSingleProperty(activeListing?.id));
      setRuletxt("");
      setEditMode(false);
    }
  };

  useEffect(() => {
    setPersonalRules(activeListing?.rules);
    setGuestCount(activeListing?.guests);
  }, [activeListing]);

  const handleCancelClick = () => {
    setGuestCount(activeListing?.guests);
    setEditMode(false);
  };

  return (
    <div className="h-fit w-full text-gray900 dark:text-gray100 border border-gray400 dark:border-gray800 rounded-3xl p-4 flex flex-col gap-2">
      <div className="flex flex-wrap md:gap-0 gap-3 items-center justify-between mb-5">
        <span className="font-bold">Personalise your listing</span>
        {editMode ? (
          <div className="flex gap-2">
            <button
              onClick={handleCancelClick}
              className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4"
            >
              Cancel
            </button>
            <button
              onClick={handleSaveClick}
              className="bg-primary500 hover:bg-primary600 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4 text-white"
            >
              Save
            </button>
          </div>
        ) : (
          <button
            onClick={() => setEditMode(true)}
            className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out flex items-center gap-2 rounded-full font-bold text-sm h-9 px-3"
          >
            Edit
            <EditIcon />
          </button>
        )}
      </div>
      {editMode ? (
        <>
          <div className="font-medium text-gray800 dark:text-gray100 flex items-center gap-2">
            <button
              onClick={() => setGuestCount((prev) => Math.max(1, prev - 1))}
              className="p-2 rounded-full border border-gray400 dark:border-gray800"
            >
              <Minusicon />
            </button>
            <span className="px-3 py-1">{guestCount}</span>
            <button
              onClick={() => setGuestCount((prev) => prev + 1)}
              className="p-2 rounded-full border border-gray400 dark:border-gray800"
            >
              <Plusicon />
            </button>
            guest(s)
          </div>

          <DropdownTwo
            label="Property rules"
            // @ts-ignore
            options={activeListing?.rules.map((item) => {
              return { value: item, label: item };
            })}
            // @ts-ignore
            onSelect={(e) => {
              if (personalRules.includes(e)) {
                return;
              }
              setPersonalRules([...personalRules, e]);
            }}
          />

          <div className="flex items-center gap-2 overflow-x-scroll w-full">
            {personalRules.map((item) => (
              <div
                className="p-2 items-center gap-2 flex flex-nowrap bg-gray300 border border-gray400 rounded-full cursor-pointer w-fit overflow-x-scrol"
                onClick={() =>
                  setPersonalRules([
                    ...personalRules.filter((itm) => itm !== item),
                  ])
                }
              >
                <div
                  className={`w-fit bg-white h-4 flex items-center justify-center border  border-primary500 rounded-md p-0.5 transition-all duration-300 ease-in-out`}
                >
                  <CheckRedIcon />
                </div>
                <p className="text-sm text-gray1000 text-nowrap">{item}</p>
              </div>
            ))}
          </div>

          <TextInput
            label="Specify other rules and seperate with comma (,)"
            placeholder="Enter rules"
            onChange={(e) => setRuletxt(e.target.value)}
            type="text"
            value={ruletxt}
            className="lg:w-full"
          />
          <div className="my-2">
            <DropdownSearch
              label="Change timezone"
              onSelect={(selectedOption: string) =>
                setSelectedTimezone(selectedOption)
              }
              options={timezone2}
              defaultValue={activeListing?.timezone}
            />
          </div>
        </>
      ) : (
        <>
          <span className="font-medium text-gray800 dark:text-gray100">
            Accepts {activeListing?.guests}{" "}
            {activeListing && activeListing?.guests > 1 ? "guests" : "guest"}
          </span>
          {activeListing?.rules?.length > 0 && (
            <div className="text-gray700 dark:text-gray100 flex flex-wrap gap-1">
              {activeListing?.rules.map((rule, index) => (
                <div key={index}>
                  {rule}
                  {index !== activeListing?.rules?.length - 1 && " ● "}
                </div>
              ))}
            </div>
          )}
          <p className="font-medium text-gray800 dark:text-gray100">Timezone</p>
          <p>{activeListing?.timezone}</p>
        </>
      )}
    </div>
  );
};

export default AboutPersonal;
