import React, { useState, useEffect } from "react";
import CalendarIcon from "../../../../components/icons/calendar";
import EditIcon from "../../../../components/icons/edit";
import { PropertyType } from "../../../../redux/types";
import Plusicon from "../../../../components/icons/plus-icon";
import { useDispatch } from "react-redux";
import {
  fetchSingleProperty,
  updatePropertyBlockedDates,
} from "../../../../redux/actions/property";
import moment from "moment";
import Modal from "../../../../components/custom/modal";
import CalendarDateSelector from "../../../../components/custom/calendar-date-selector";

interface PropertyAvalabilityProps {
  activeListing: PropertyType;
}

const BlockedDates: React.FC<PropertyAvalabilityProps> = ({
  activeListing,
}) => {
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const [blockedDates, setBlockedDates] = useState<string[]>([]);
  const [blockedEditDates, setBlockedEditDates] = useState<
    { should_store: boolean; date: string }[]
  >([]);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (activeListing?.blocked_out_dates) {
      setBlockedDates([...activeListing?.blocked_out_dates]);
    }
  }, [activeListing]);

  const handleSaveClick = async () => {
    const res = await dispatch(
      // @ts-ignore
      updatePropertyBlockedDates({
        property_id: activeListing?.id,
        blocked_out_dates: [
          ...blockedEditDates,
          ...blockedDates.map((item) => {
            return { should_store: true, date: item };
          }),
        ],
      })
    );

    if (res) {
      // @ts-ignore
      dispatch(fetchSingleProperty(activeListing?.id));
      setEditMode(false);
    }
  };

  const handleCancelClick = () => {
    setEditMode(false);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    document.body.style.overflowY = "auto";
  };

  return (
    <div className="h-fit w-full text-gray900 dark:text-gray100 border border-gray400 dark:border-gray800 rounded-3xl p-4 flex flex-col gap-2">
      <div className="flex flex-wrap md:gap-0 gap-3 items-center justify-between mb-5">
        <span className="font-bold">Block Out</span>
        {editMode ? (
          <div className="flex gap-2">
            <button
              onClick={handleCancelClick}
              className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4"
            >
              Cancel
            </button>
            <button
              onClick={handleSaveClick}
              className="bg-primary500 hover:bg-primary600 transition-all duration-200 ease-in-out h-10 flex items-center gap-2 rounded-full font-bold text-sm px-4 text-white"
            >
              Save
            </button>
          </div>
        ) : (
          <button
            onClick={() => setEditMode(true)}
            className="bg-gray400 dark:bg-gray800 hover:bg-gray500 transition-all duration-200 ease-in-out flex items-center gap-2 rounded-full font-bold text-sm h-9 px-3"
          >
            Edit
            <EditIcon />
          </button>
        )}
      </div>
      {editMode ? (
        <div
          className={`h-fit text-gray900 dark:text-gray100 flex flex-col gap-2 ${
            editMode
              ? "border border-gray400 dark:border-gray800 rounded-3xl p-4 md:w-[30rem]"
              : ""
          }`}
        >
          <div className="flex items-center gap-2 justify-between w-full">
            <p className="text-sm font-bold">Add block out date</p>
            <button
              onClick={() => setIsModalOpen(true)}
              className="p-2 rounded-full border w-fit  dark:border-gray800 border-gray400"
            >
              <Plusicon />
            </button>
          </div>
          <div className="flex-col gap-1 grid grid-cols-2">
            {blockedDates?.map((item, i) => {
              return (
                <div
                  key={i}
                  className="flex items-center justify-between w-full rounded-full border dark:border-gray800 border-gray400 px-2"
                >
                  <p>{moment(item).format("MMM DD")}</p>
                  <button
                    onClick={() => {
                      setBlockedDates([
                        ...blockedDates.filter((dates) => dates !== item),
                      ]);
                      setBlockedEditDates([
                        ...blockedEditDates,
                        { should_store: false, date: item },
                      ]);
                    }}
                    className="p-1 px-3 rounded-full w-fit "
                  >
                    x
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex items-center gap-1">
          <div className="flex flex-col items-center gap-1">
            {activeListing && activeListing?.blocked_out_dates?.length > 0 ? (
              activeListing?.blocked_out_dates?.map((date, index) => (
                <div className="flex gap-1" key={index}>
                  <CalendarIcon /> <span>{moment(date).format("MMM DD")}</span>
                </div>
              ))
            ) : (
              <span className="text-gray800 font-medium text-sm">
                No blocked dates
              </span>
            )}
          </div>
        </div>
      )}

      <Modal className="bg-transparent" open={isModalOpen} onClose={closeModal}>
        <div className="p-3">
          <CalendarDateSelector
            updateActiveListing={(dates) => {
              console.log("date pickksss", dates);
              // @ts-ignore
              setBlockedDates([...dates]);
              closeModal();
            }}
            blockedOutDates={blockedDates}
            onClose={closeModal}
          />
        </div>
      </Modal>
    </div>
  );
};

export default BlockedDates;
