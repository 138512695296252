import { useForm, FormProvider } from "react-hook-form";
import BreadcrumbsTwo from "../../host-dashboard/components/breadcrumb-two";
import { paths } from "../../../../routes/paths";
import Navbar from "../../../../components/custom/navbar";
import ShareIcon from "../../../../components/icons/share";
import Carousel from "../../../../components/custom/carousel";
import { useNavigate, useParams } from "react-router-dom";
import { FaCheck } from "react-icons/fa6";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { PiHouseSimpleBold } from "react-icons/pi";
import { FiUser } from "react-icons/fi";
import { CgCreditCard } from "react-icons/cg";
import Footer from "../../../../components/custom/footer";
import {
  PhoneInputField,
  TextAreaInput,
  TextInput,
} from "../../../../components/custom/text-input";
import { useEffect, useMemo, useState } from "react";
import { BsChevronRight } from "react-icons/bs";
import ReceiptEdge from "../../../../components/icons/ReceiptEdge";
import {
  useBookingInfo,
  useBookingSummary,
} from "../../../../hooks/use-book-summary";
import useQuery from "../../../../hooks/use-query";
import { RWebShare } from "react-web-share";
import moment from "moment";
import {
  currencyFormat,
  validateEmail,
} from "../../../../utils/helper-functions";
import apiClient from "../../../../network/axios-client";
import { urls } from "../../../../network";
import DatePicker from "react-datepicker";
import { addDays, eachDayOfInterval, getDay } from "date-fns";
import Minusicon from "../../../../components/icons/minus-icon";
import Plusicon from "../../../../components/icons/plus-icon";
import ToggleButton from "../../../../components/custom/toggle-button";
import { GoGift } from "react-icons/go";
import Toc from "../../guest/components/Toc";
import Modal from "../../../../components/custom/modal";

const Booking = () => {
  const { id } = useParams();
  const query = useQuery();
  // const [phone, setPhone] = useState("");
  const navigate = useNavigate();
  // const profile = useSelector(getCurrentUser);
  // const [recipientPhone, setRecipientPhone] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const [isToggleActive, setIsToggleActive] = useState(false);
  // const paymentOptions = ["Credit Card", "Debit Card"];
  // const [paymentMethod, setPaymentMethod] = useState("");
  const methods = useForm();
  const [open, setOpen] = useState(false);
  const [toc, setToc] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrormsg] = useState("");
  const [comment, setComment] = useState("");
  const [userDetails, setUserDetails] = useState({
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
  });

  useEffect(() => {
    if (query.get("reference")) {
      const referenceCode = query.get("reference") as string;
      verifyPayment(referenceCode);
    }

    // eslint-disable-next-line
  }, [query]);

  const { isLoadingBookinginfo, singleBookingInfo, fetchSingleBookingInfo } =
    useBookingInfo({
      bookingId: Number(id),
    });

  const handleToggle = () => {
    setIsToggleActive(!isToggleActive);
  };

  const handleComplete = () => {
    setIsCompleted(!isCompleted);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const handleDropdownSelect = (selectedOption: SetStateAction<string>) => {
  //   setPaymentMethod(selectedOption);
  // };

  const initiatePayment = async () => {
    if (
      isToggleActive &&
      (userDetails.first_name === "" ||
        userDetails.last_name === "" ||
        userDetails.phone === "" ||
        !validateEmail(userDetails.email))
    ) {
      setErrormsg("Please kindly fill all guest infomation fields");
      return;
    }

    const payload = {
      booking_id: id,
      callback_url: `${process.env.REACT_APP_WEB_URL}${paths.BOOKING}/${id}`,
      comment: comment.length > 0 ? comment : null,
      ...(isToggleActive ? { guest_info: userDetails } : {}),
      booking_type: isToggleActive ? "friend" : "self",
    };
    setErrormsg("");
    setIsLoading(true);
    try {
      const res = await apiClient.post(urls.BookingPay, payload);
      setIsLoading(false);
      if (res.data.hasError) {
        setErrormsg(res.data.errors.message);
      } else {
        setErrormsg("");
        const { paystackData } = res.data.data;
        // Open Paystack payment page
        window.location.href = paystackData?.authorization_url;
      }
    } catch (error) {
      console.log("Error initializing payment", error);
      setErrormsg("Error initializing payment");
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const verifyPayment = async (id: string) => {
    try {
      setIsLoading(true);
      const res = await apiClient.get(urls.PaymentVerify(id));
      setIsLoading(false);
      if (res.data.hasError) {
        setErrormsg(res.data.errors.message);
      } else {
        handleComplete();
      }
    } catch (error) {}
  };

  const eadFee = useMemo(() => {
    return (
      (Number(singleBookingInfo?.booking?.total_amount_paid) -
        Number(singleBookingInfo?.booking?.property_rate)) *
        Number(singleBookingInfo?.booking?.number_of_nights) || 0
    );
  }, [singleBookingInfo?.booking]);

  //  edit logic section
  const [isEdit, setEdit] = useState(false);
  const [countChildren, setCountChildren] = useState(0);
  const [countAdult, setCountAdult] = useState(1);
  const [checkinDate, setCheckinDate] = useState<Date>(
    addDays(new Date()?.toISOString(), 1) || new Date()
  );
  const [checkoutDate, setCheckoutDate] = useState<Date>(
    addDays(new Date()?.toISOString(), 2) || new Date()
  );
  const [isDateOpen, setIsDateopen] = useState("hidden");
  const [warning, setWarning] = useState("");
  // const [noOfDays, setNoDays] = useState(0);

  useEffect(() => {
    if (singleBookingInfo?.booking?.check_in_date) {
      setCheckinDate(new Date(singleBookingInfo?.booking?.check_in_date));
    }
    if (singleBookingInfo?.booking?.check_out_date) {
      setCheckoutDate(new Date(singleBookingInfo?.booking?.check_out_date));
    }
  }, [singleBookingInfo?.booking]);

  const {
    // bookingSummary,
    editbookingApi,
    bookingSummaryErrMsg,
    // isLoadingBookSummary,
    // isInitiateLoading,
    // initiateBooking,
  } = useBookingSummary({
    checkinDate,
    checkoutDate,
    countAdult,
    countChildren,
    propertyId: singleBookingInfo?.booking?.property_json?.id || 1,
  });

  const getAvaliableDays = useMemo(
    () => [
      singleBookingInfo?.booking?.property_json?.monday_availability ? null : 1,
      singleBookingInfo?.booking?.property_json?.tuesday_availability
        ? null
        : 2,
      singleBookingInfo?.booking?.property_json?.wednesday_availability
        ? null
        : 3,
      singleBookingInfo?.booking?.property_json?.thursday_availability
        ? null
        : 4,
      singleBookingInfo?.booking?.property_json?.friday_availability ? null : 5,
      singleBookingInfo?.booking?.property_json?.saturday_availability
        ? null
        : 6,
      singleBookingInfo?.booking?.property_json?.sunday_availability ? null : 0,
    ],

    // eslint-disable-next-line
    [singleBookingInfo?.booking?.property_json]
  );

  const customDisabledDates =
    singleBookingInfo?.booking?.property_json?.blocked_out_dates?.map(
      (item) => new Date(item)
    );

  const isWeekdayDisabled = useMemo(
    () => (date: any) => {
      const day = getDay(date);
      const disableAvailableDays = getAvaliableDays
        .filter((item) => item !== null)
        .some((disableDay) => day === disableDay);
      return disableAvailableDays; // Disable days and custom dates
    },
    // eslint-disable-next-line
    [
      singleBookingInfo?.booking?.property_json,
      checkinDate,
      checkoutDate,
      getAvaliableDays,
    ]
  );

  const onChange = (dates: [any, any]) => {
    const [start, end] = dates;
    setWarning("");
    setCheckinDate(start);
    setCheckoutDate(end);
    if (start && end) {
      const allDates = eachDayOfInterval({ start, end });
      const hasDisabledDate = allDates.some(isWeekdayDisabled);
      if (hasDisabledDate) {
        setWarning(
          "Warning: The selected date range includes a disabled date."
        );
      } else {
        setWarning("");
      }
    }
    if (end) {
      setIsDateopen("hidden");
    }
  };

  const handleEditBooking = async () => {
    const res = await editbookingApi({
      bookingId: singleBookingInfo?.booking?.id || Number(id),
      booking_type: isToggleActive ? "friend" : "self",
      comment: comment.length > 0 ? comment : null,
      ...(isToggleActive ? { guestInfo: userDetails } : {}),
    });
    if (res) {
      fetchSingleBookingInfo(singleBookingInfo?.booking?.id);
      setEdit(false);
      setUserDetails({
        first_name: "",
        last_name: "",
        phone: "",
        email: "",
      });
      setComment("");
      setIsToggleActive(false);
    }
  };

  useEffect(() => {
    if (
      singleBookingInfo?.booking?.guest_info &&
      singleBookingInfo?.booking?.type === "friend"
    ) {
      setIsToggleActive(true);
      setUserDetails({
        first_name: singleBookingInfo?.booking?.guest_info?.first_name,
        last_name: singleBookingInfo?.booking?.guest_info?.last_name,
        email: singleBookingInfo?.booking?.guest_info?.email,
        phone: singleBookingInfo?.booking?.guest_info?.phone,
      });
    } else {
      setIsToggleActive(false);
    }
    setComment(singleBookingInfo?.booking?.comment || "");
  }, [singleBookingInfo?.booking]);

  return (
    <div className="bg-eadWhite min-h-screen relative pb-18 px-4 dark:bg-gray1000 font-display text-gray1000 dark:text-gray100">
      <div className="max-w-screen-2xl mx-auto">
        <Navbar />
      </div>
      {isCompleted ? (
        <div className="max-w-screen-lg mx-auto lg:my-10">
          <div className="border border-gray400 dark:border-gray800 rounded-2xl p-5 md:p-10 flex md:flex-row flex-col-reverse items-center gap-10 md:gap-3">
            <div className="md:w-[55%] flex flex-col gap-3 text-gray900 dark:text-gray100">
              <div className="flex md:flex-col flex-row-reverse md:items-start items-center justify-end md:w-auto w-full gap-3">
                {" "}
                <FaCheck className="md:text-6xl text-3xl text-green-600" />
                <span className="md:text-4xl text-2xl font-bold tracking-tighter">
                  Reservation Completed!
                </span>
              </div>
              <span className="">
                Your reservation has now been completed successfully, the host
                will confirm your booking, usually in 24hrs. A message and email
                containing your booking details will be sent to you.
              </span>
              <button
                onClick={() => {
                  navigate(
                    `${paths.GUEST}/booking-details/${singleBookingInfo?.booking?.id}`
                  );
                }}
                className="bg-primary500 w-fit font-display text-sm font-bold text-eadWhite text-center h-9 flex items-center justify-center px-3 rounded-full mt-4"
              >
                View booking details
              </button>
            </div>
            <div className="md:w-[45%] flex flex-col">
              <ReceiptEdge className="-mb-0.5 w-full" />
              <div className="w-full bg-gray300 dark:bg-gray900 p-5">
                <div className="flex md:flex-row flex-col-reverse items-center gap-2 md:gap-5">
                  <div className="flex flex-col items-start md:w-auto w-full gap-1">
                    <span className="text-gray900 dark:text-gray100 text-lg font-bold">
                      {singleBookingInfo?.booking?.property_json?.name}
                    </span>
                    <span className="flex items-center gap-1 text-gray800 dark:text-gray100 text-sm">
                      <HiOutlineLocationMarker className="text-base" />
                      {singleBookingInfo?.booking?.property_json?.address}
                    </span>
                  </div>
                  <img
                    src={
                      (singleBookingInfo?.booking?.property_json
                        ?.property_media &&
                        singleBookingInfo?.booking?.property_json
                          ?.property_media[0]?.url) ||
                      ""
                    }
                    alt=""
                    className="md:w-1/3 rounded-xl bg-gray400"
                  />
                </div>
                <hr className="border-gray400 my-3 dark:border-gray800" />
                <div className="flex items-center justify-between">
                  <div className="flex flex-col gap-2 text-sm">
                    Checkin From
                    {isLoadingBookinginfo ? (
                      <div className="animate-pulse">
                        <div className="h-5 w-20 bg-gray600 rounded"></div>
                      </div>
                    ) : (
                      <span className="font-bold text-2xl md:text-3xl text-gray900 dark:text-gray100">
                        {moment(
                          singleBookingInfo?.booking?.check_in_date
                        ).format("D/MM/YY")}
                      </span>
                    )}
                    {moment(singleBookingInfo?.booking?.check_in_date).format(
                      "dddd"
                    )}{" "}
                    {singleBookingInfo?.booking?.property_json?.check_in_time}
                  </div>
                  <BsChevronRight className="text-gray700 text-3xl" />
                  <div className="flex flex-col gap-2 text-sm">
                    Check out
                    {isLoadingBookinginfo ? (
                      <div className="animate-pulse">
                        <div className="h-5 w-20 bg-gray600 rounded"></div>
                      </div>
                    ) : (
                      <span className="font-bold text-2xl md:text-3xl text-gray900 dark:text-gray100">
                        {moment(
                          singleBookingInfo?.booking?.check_out_date
                        ).format("D/MM/YY")}
                      </span>
                    )}
                    {moment(singleBookingInfo?.booking?.check_out_date).format(
                      "dddd"
                    )}{" "}
                    {singleBookingInfo?.booking?.property_json?.check_out_time}
                  </div>
                </div>
                <hr className="border-gray400 my-3 dark:border-gray800" />
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-sm">
                      {currencyFormat(
                        singleBookingInfo?.booking?.property_rate || 0
                      )}{" "}
                      x{singleBookingInfo?.booking?.number_of_nights} night
                    </span>
                  )}
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(
                        singleBookingInfo?.booking?.property_rate || 0
                      )}
                    </span>
                  )}
                </div>
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  <span className="text-sm">EAD Booking Fee</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(eadFee || 0)}
                    </span>
                  )}
                </div>
                {/* <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  <span className="text-sm">VAT</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(singleBookingInfo?.booking?.vat || 0)}
                    </span>
                  )}
                </div> */}
                <hr className="border-gray400 my-3 dark:border-gray800" />
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100 font-bold">
                  <span className="text-sm">Total</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg">
                      {currencyFormat(
                        singleBookingInfo?.booking?.total_amount_paid || 0
                      )}
                    </span>
                  )}
                </div>
              </div>
              <ReceiptEdge className="-mt-0.5 rotate-180 w-full" />
            </div>
          </div>
        </div>
      ) : (
        <FormProvider {...methods}>
          <div className="max-w-screen-lg mx-auto lg:my-10">
            <div className="flex justify-between items-center">
              <BreadcrumbsTwo name={"Secure booking"} path={paths.BOOKING} />

              <RWebShare
                data={{
                  text: singleBookingInfo?.booking?.property_json?.name,
                  url: singleBookingInfo?.booking?.property_json?.share_link,
                  title: singleBookingInfo?.booking?.property_json?.name,
                }}
                onClick={() => console.log("shared successfully!")}
              >
                <button className="bg-gray400 dark:bg-gray900 dark:hover:bg-gray800 hover:bg-gray500 transition-all duration-300 ease-in-out text-gray1000 dark:text-gray100 text-sm px-3 rounded-full w-fit flex font-semibold items-center justify-center gap-2 p-2">
                  Share
                  <ShareIcon className="dark:stroke-gray600" />
                </button>
              </RWebShare>
            </div>
            <hr className="border-gray400 my-6 hidden lg:block dark:border-gray800" />
            {errorMsg.length > 1 && (
              <p className="text-base text-primary500 m-2">{errorMsg}</p>
            )}
            <div className="flex md:flex-row flex-col gap-5">
              {/* left */}
              <div className="md:w-[65%] md:mt-0 mt-5 flex flex-col gap-5">
                {/* Confirm your phone number */}
                <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  {/* <span className="text-xl font-bold mb-1">
                    Confirm your phone number
                  </span>
                  <PhoneInputField
                    label="Phone number"
                    value={phone}
                    className="lg:w-full dark:text-gray1000"
                    onChange={(phone) => setPhone(phone)}
                    inputProps={{
                      name: "phone",
                      required: true,
                      autoFocus: true,
                    }}
                    // isValid={phone.length > 9}
                  />
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="accent-primary500"
                      name=""
                      id=""
                    />
                    <p className="text-xs text-gray800 dark:text-gray100 font-medium">
                      Receive text alerts about this booking (free of charge).
                    </p>
                  </div> */}
                  {/* <hr className="border-gray400 my-2 dark:border-gray800" /> */}
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <div className="w-12 h-12 flex items-center justify-center rounded-full bg-primary100 p-2.5">
                        <GoGift className="w-full h-full object-cover text-primary500" />
                      </div>
                      <div className="flex flex-col justify-between font-medium text-sm">
                        <span>Book for someone else</span>
                        <span className="text-gray700">
                          If this booking is for someone else
                        </span>
                      </div>
                    </div>
                    <ToggleButton
                      isActive={isToggleActive}
                      onToggle={handleToggle}
                    />
                  </div>
                  <div
                    className={`flex flex-col gap-2.5 transition-all duration-300 ease-in-out ${
                      isToggleActive ? "scale-100" : "scale-0 h-0"
                    }`}
                  >
                    <div className="flex items-center flex-wrap gap-2">
                      <TextInput
                        label="First name"
                        placeholder="Enter first name"
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            first_name: e.target.value,
                          })
                        }
                        className="lg:w-72"
                        value={userDetails.first_name}
                      />
                      <TextInput
                        label="Last name"
                        placeholder="Enter last name"
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            last_name: e.target.value,
                          })
                        }
                        className="lg:w-72"
                        value={userDetails.last_name}
                      />
                      <TextInput
                        label="Email"
                        placeholder="Enter email"
                        onChange={(e) =>
                          setUserDetails({
                            ...userDetails,
                            email: e.target.value,
                          })
                        }
                        className="lg:w-72"
                        value={userDetails.email}
                      />
                      <PhoneInputField
                        label="Recipient phone number"
                        className="lg:w-72"
                        onChange={(phone) =>
                          setUserDetails({ ...userDetails, phone: phone })
                        }
                        inputProps={{
                          name: "phone",
                          required: true,
                          autoFocus: true,
                        }}
                        value={userDetails.phone}
                        // isValid={phone.length > 9}
                      />
                    </div>
                    {/* <div className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="accent-primary500"
                        name=""
                        id=""
                      />
                      <p className="text-xs text-gray800 dark:text-gray100 font-medium">
                        Receive text alerts about this booking (free of charge).
                      </p>
                    </div> */}
                  </div>
                </div>
                {/* How to pay */}
                {/* <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">How to pay</span>
                  <div className="flex flex-col gap-1 text-sm">
                    <label htmlFor="">Payment mode</label>
                    <Dropdown
                      onSelect={handleDropdownSelect}
                      options={paymentOptions}
                      defaultValue="Credit or Debit Card"
                      icon={
                        <FiUser className="text-lg text-gray800 dark:text-gray100" />
                      }
                    />
                  </div>
                  <hr className="border-gray400 my-2 dark:border-gray800" />
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-[70%]">
                      <InputField
                        className="lg:w-full text-base"
                        label="Name on card"
                        placeholder="Enter your full name"
                        type="text"
                        name="name"
                      />
                    </div>
                    <div className="w-[30%]">
                      {" "}
                      <InputField
                        className="lg:w-full text-base"
                        label="Expiry"
                        placeholder="06/2024"
                        type="number"
                        name="expiry"
                      />
                    </div>
                  </div>{" "}
                  <div className="flex items-center gap-3 w-full">
                    <div className="w-[70%]">
                      <InputField
                        className="lg:w-full text-base"
                        label="Card number"
                        placeholder="Enter your card number"
                        type="text"
                        name="card number"
                        icon={<img alt="masterCard" src={MasterCard} />}
                      />
                    </div>
                    <div className="w-[30%]">
                      {" "}
                      <InputField
                        className="lg:w-full text-base"
                        label="CVV"
                        placeholder="•••"
                        type="password"
                        name="cvv"
                      />
                    </div>
                  </div>
                </div> */}
                {/* Send message to host */}
                <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">
                    Send message to host
                  </span>
                  <div className="flex flex-col gap-1 text-sm">
                    <TextAreaInput
                      placeholder="Enter description"
                      label="Your message (optional)"
                      onChange={(val: any) => setComment(val.target.value)}
                      value={comment}
                      className="dark:bg-transparent"
                    />
                  </div>
                </div>
                {/* Agreement */}
                {/* <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">Agreement</span>
                  <span className="text-gray800 dark:text-gray100 text-sm">
                    Your agreement is with Eve After Dark{" "}
                    <p className="mt-2">
                      By selecting Confirm and pay, I agree to the Host's House
                      Rules, Ground rules for guests, and Eve After Dark Refund
                      Policy.
                    </p>
                  </span>
                </div> */}
                {/* Cancellation Policy */}
                <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">
                    Cancellation Policy
                  </span>
                  <ul className="text-sm text-gray800 dark:text-gray100 list-disc px-5 flex flex-col gap-2">
                    <li>
                      ⁠Booking cancellation is supported only if your host
                      cancels this booking. If you change your mind about this
                      booking after payment has been made, you will not get a
                      refund.
                    </li>{" "}
                    <li>
                      No refund will be issued for late check-in or early check
                      out.
                    </li>
                    <li>Stay extensions require a new reservation.</li>
                  </ul>
                </div>
                {/* Ground rules */}
                <div className="p-3 flex gap-2.5 flex-col rounded-2xl border border-gray400  dark:border-gray800 w-full">
                  <span className="text-xl font-bold mb-1">Ground Rules</span>
                  <ul className="text-sm text-gray800 dark:text-gray100 list-disc px-5 flex flex-col gap-2">
                    {singleBookingInfo?.booking?.property_json?.rules.map(
                      (item: any) => (
                        <li key={item}>{item}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
              {/* right */}
              <div className="p-3 flex-grow-0 h-fit rounded-2xl border border-gray400  dark:border-gray800 md:w-[35%] flex flex-col gap-2 text-gray900 dark:text-gray100">
                <Carousel
                  slides={
                    singleBookingInfo?.booking?.property_json?.property_media?.map(
                      (item) => ({ url: item.url, title: item.url })
                    ) || []
                  }
                  classNameImg="rounded-2xl object-cover w-full h-[10rem]"
                />
                {isLoadingBookinginfo ? (
                  <div className="animate-pulse">
                    <div className="h-5 w-20 bg-gray600 rounded"></div>
                  </div>
                ) : (
                  <span className="text-lg font-bold">
                    {singleBookingInfo?.booking?.property_json?.name}
                  </span>
                )}
                {/* <div className="flex items-center gap-1 text-sm">
                  <FaStar className="text-amber-500 text-base" />
                  <span className="text-gray800 dark:text-gray100 font-semibold">
                    4.50 - 4 reviews
                  </span>
                </div> */}
                <hr className="border-gray400 my-2 dark:border-gray800" />

                {/* edit booking */}
                {isEdit ? (
                  <div>
                    <div className="relative">
                      <p className="text-sm font-semibold text-primary500">
                        {bookingSummaryErrMsg}
                      </p>
                      <p className="text-sm font-semibold text-primary500">
                        {warning}
                      </p>
                      <div
                        onClick={() =>
                          setIsDateopen(
                            isDateOpen === "flex" ? "hidden" : "flex"
                          )
                        }
                        className="cursor-pointer z-50 flex p-2 border border-gray400 dark:border-gray800 rounded-lg justify-between my-4 flex-shrink-0"
                      >
                        <div className="border-r w-1/2">
                          <p className="text-sm font-display dark:text-gray200 ">
                            Checkin Date
                          </p>
                          <p className="text-sm text-gray1000 dark:text-gray200">
                            {moment(checkinDate).format("DD-MM-yyyy")}
                          </p>
                        </div>
                        <div className="pl-2 w-1/2">
                          <p className="text-sm font-display dark:text-gray200">
                            Checkout Date
                          </p>
                          <p className="text-sm text-gray1000 dark:text-gray200">
                            {moment(checkoutDate).format("DD-MM-yyyy")}
                          </p>
                        </div>
                      </div>
                      <div className={`transition-all ${isDateOpen}`}>
                        <DatePicker
                          minDate={new Date()}
                          filterDate={(date) => !isWeekdayDisabled(date)}
                          excludeDates={customDisabledDates}
                          onChange={onChange}
                          startDate={checkinDate}
                          //@ts-ignore
                          endDate={checkoutDate}
                          selectsRange
                          inline
                          className="font-display"
                        />
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm text-gray1000 font-display dark:text-gray200">
                          Adults
                        </p>
                        <p className="text-xs text-gray700 font-display dark:text-gray200">
                          Age 13+
                        </p>
                      </div>

                      <div className="flex gap-4 items-center mt-3">
                        <button
                          onClick={() =>
                            setCountAdult((prev) => (prev === 0 ? 0 : prev - 1))
                          }
                          className=" p-2  rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                        >
                          <Minusicon />
                        </button>
                        <div className="py-2 px-4  rounded-full border border-gray500 dark:bg-gray1000 dark:border-gray1000">
                          <p className="font-display text-base dark:text-gray200">
                            {countAdult}
                          </p>
                        </div>
                        <button
                          onClick={() =>
                            setCountAdult((prev) =>
                              prev >=
                              (singleBookingInfo?.booking?.property_json
                                ?.guests || 1)
                                ? prev
                                : prev + 1
                            )
                          }
                          className="p-2  rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                        >
                          <Plusicon />
                        </button>
                      </div>
                    </div>
                    <div className="flex items-center justify-between">
                      <div>
                        <p className="text-sm text-gray1000 font-display dark:text-gray200">
                          Children
                        </p>
                        <p className="text-xs text-gray700 font-display dark:text-gray200">
                          Under 12
                        </p>
                      </div>

                      <div className="flex gap-4 items-center mt-3">
                        <button
                          onClick={() =>
                            setCountChildren((prev) =>
                              prev === 0 ? 0 : prev - 1
                            )
                          }
                          className="p-2 rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                        >
                          <Minusicon />
                        </button>
                        <div className="py-2 px-4  rounded-full border border-gray500 dark:bg-gray1000 dark:border-gray1000">
                          <p className="font-display text-base dark:text-gray200">
                            {countChildren}
                          </p>
                        </div>
                        <button
                          onClick={() => setCountChildren((prev) => prev + 1)}
                          className="p-2  rounded-full border border-gray500 bg-gray400 dark:bg-gray600 dark:border-gray800"
                        >
                          <Plusicon />
                        </button>
                      </div>
                    </div>

                    <button
                      onClick={handleEditBooking}
                      className={`bg-primary500 w-full hover:bg-primary400 transition-all duration-200 ease-in-out mt-4 text-white rounded-full flex font-semibold items-center justify-center text-sm p-2`}
                    >
                      Update booking
                    </button>
                    <button
                      onClick={() => setEdit(false)}
                      className="font-semibold w-full text-center dark:text-gray200 text-sm my-2 p-2"
                    >
                      Cancel
                    </button>
                  </div>
                ) : (
                  <div>
                    <div className="flex items-center gap-10 text-gray700 text-sm">
                      <span className="flex items-center gap-3">
                        From
                        {isLoadingBookinginfo ? (
                          <div className="animate-pulse">
                            <div className="h-5 w-20 bg-gray600 rounded"></div>
                          </div>
                        ) : (
                          <span className="font-bold">
                            {moment(
                              singleBookingInfo?.booking?.check_in_date
                            ).format("D/MM/YYYY")}
                          </span>
                        )}
                      </span>
                      <span className="flex items-center gap-3">
                        To
                        {isLoadingBookinginfo ? (
                          <div className="animate-pulse">
                            <div className="h-5 w-20 bg-gray600 rounded"></div>
                          </div>
                        ) : (
                          <span className="font-bold">
                            {moment(
                              singleBookingInfo?.booking?.check_out_date
                            ).format("D/MM/YYYY")}
                          </span>
                        )}
                      </span>
                    </div>
                    <div className="flex items-center gap-2 text-gray700">
                      <PiHouseSimpleBold className="text-lg" />
                      {isLoadingBookinginfo ? (
                        <div className="animate-pulse">
                          <div className="h-5 w-20 bg-gray600 rounded"></div>
                        </div>
                      ) : (
                        <span className="text-sm">
                          {singleBookingInfo?.booking?.number_of_nights}-night
                          stay
                        </span>
                      )}
                    </div>
                    <div className="flex justify-between items-center">
                      <div className="flex items-center gap-2 text-gray700">
                        <FiUser className="text-lg" />
                        {isLoadingBookinginfo ? (
                          <div className="animate-pulse">
                            <div className="h-5 w-20 bg-gray600 rounded"></div>
                          </div>
                        ) : (
                          <span className="text-sm">
                            {singleBookingInfo?.booking?.number_of_adults}{" "}
                            guests
                          </span>
                        )}
                      </div>
                      <button
                        onClick={() => setEdit(true)}
                        className="font-semibold text-gray800 dark:text-gray100 text-sm"
                      >
                        Edit booking
                      </button>
                    </div>
                  </div>
                )}

                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-sm">
                      {currencyFormat(
                        singleBookingInfo?.booking?.property_rate || 0
                      )}{" "}
                      x{singleBookingInfo?.booking?.number_of_nights} night
                    </span>
                  )}
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(
                        singleBookingInfo?.booking?.property_rate || 0
                      )}
                    </span>
                  )}
                </div>
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  <span className="text-sm">EAD Booking Fee</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(eadFee || 0)}
                    </span>
                  )}
                </div>
                {/* <div className="flex justify-between items-center text-gray1000 dark:text-gray100">
                  <span className="text-sm">VAT</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg font-medium">
                      {currencyFormat(singleBookingInfo?.booking?.vat || 0)}
                    </span>
                  )}
                </div> */}
                <hr className="border-gray400 my-2 dark:border-gray800" />
                <div className="flex justify-between items-center text-gray1000 dark:text-gray100 font-bold">
                  <span className="text-sm">Total</span>
                  {isLoadingBookinginfo ? (
                    <div className="animate-pulse">
                      <div className="h-5 w-20 bg-gray600 rounded"></div>
                    </div>
                  ) : (
                    <span className="text-lg">
                      {currencyFormat(
                        singleBookingInfo?.booking?.total_amount_paid || 0
                      )}
                    </span>
                  )}
                </div>
                {!isEdit && (
                  <div className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      className="accent-primary500"
                      name=""
                      id=""
                      onChange={() => {
                        setToc((prev) => !prev);
                      }}
                      checked={toc}
                    />
                    <p className="text-xs text-gray800 dark:text-gray100  font-medium">
                      I agree to all the
                      <span
                        onClick={() => setOpen(true)}
                        className="underline cursor-pointer text-xs"
                      >
                        {" "}
                        terms & conditions
                      </span>
                    </p>
                  </div>
                )}

                {!isEdit && (
                  <button
                    disabled={!toc || isLoading || isEdit}
                    className={`${
                      toc ? "bg-primary500" : "bg-primary200"
                    } cursor-pointer text-nowrap hover:bg-primary400 transition-all duration-200 ease-in-out my-3 text-white rounded-full flex font-semibold items-center justify-center text-sm p-3 gap-2`}
                    onClick={initiatePayment}
                  >
                    {isLoading && (
                      <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500 mx-1" />
                    )}
                    <CgCreditCard className="text-lg" />
                    Confirm reservation and pay
                  </button>
                )}
              </div>
            </div>
          </div>
        </FormProvider>
      )}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        className="lg:w-1/2"
      >
        <div className="relativ flex items-center justify-center">
          <div className="w-full overflow-y-scroll h-72">
            <Toc />
          </div>
          <button
            onClick={() => setOpen(false)}
            className="rounded-full bg-primary500 text-gray200 py-1 px-3 text-sm font-medium absolute bottom-3"
          >
            Okay
          </button>
        </div>
      </Modal>
      <Footer />
    </div>
  );
};

export default Booking;
