import { useDispatch, useSelector } from "react-redux";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";
import {
  getAllBanks,
  getCurrentUser,
} from "../../../../redux/reducers/user-slice";
import { useFormFields } from "../../../../hooks/use-form-fields";
import { TextInput } from "../../../../components/custom/text-input";
import { SelectInput } from "../../../../components/custom/select-input";
import {
  VerifyBankDetails,
  fetchAllBanks,
  updateBankDetails,
} from "../../../../redux/actions/authuser";
import { useCallback, useEffect, useState } from "react";
import Modal from "../../../../components/custom/modal";

const PaymentInfo = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector(getIsLoading);
  const userInfo = useSelector(getCurrentUser);
  const allBanks = useSelector(getAllBanks);
  const [errmsg, setErrMsg] = useState("");
  const [openMsg, setOpenMsg] = useState(false);
  const [formfields, setFieldValue] = useFormFields({
    account_name: "",
    account_number: "",
    bank_name: "",
    bank_code: "",
    country: "Nigeria",
  });

  useEffect(() => {
    setFieldValue("account_number", userInfo?.account_number);
    setFieldValue("country", "Nigeria");
    // setFieldValue("account_name", userInfo.account_name);
    setFieldValue(
      "bank_name",
      // @ts-ignore
      allBanks?.filter((item) => item.name === userInfo?.bank_name)[0]?.name
    );
    setFieldValue(
      "bank_code",
      // @ts-ignore
      allBanks?.filter((item) => item.name === userInfo?.bank_name)[0]?.code
    );

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (formfields.country.length > 1) {
      // @ts-ignore
      dispatch(fetchAllBanks(formfields.country));
    }

    // eslint-disable-next-line
  }, [formfields.country]);

  useEffect(() => {
    getAccountName();

    // eslint-disable-next-line
  }, [formfields?.account_number]);

  const getAccountName = useCallback(async () => {
    setErrMsg("");
    setFieldValue("account_name", "");
    if (
      formfields?.account_number?.length === 10 &&
      formfields?.bank_code?.length > 0
    ) {
      const res = await dispatch(
        // @ts-ignore
        VerifyBankDetails({
          bank_code: formfields?.bank_code,
          account_number: formfields?.account_number,
        })
      );

      if (res) {
        // @ts-ignore
        setFieldValue("account_name", res.account_name);
      } else {
        setErrMsg("Account details is not correct, please try again.");
      }
    }
    // eslint-disable-next-line
  }, [formfields?.account_number, formfields?.bank_code]);

  const saveBankDetails = async () => {
    //@ts-ignore
    const res = await dispatch(
      // @ts-ignore
      updateBankDetails({
        bank_name: allBanks?.filter(
          //@ts-ignore
          (item) => item.code === formfields.bank_code
          //@ts-ignore
        )[0].name,
        bank_code: formfields.bank_code,
        account_name: formfields.account_name,
        account_number: formfields.account_number,
        account_country: formfields.country,
      })
    );
    if (res) {
    }
  };

  console.log('userInfo...',userInfo)

  return (
    <div className="">
      <div className="pt-2">
        <div className="flex flex-col rounded-2xl p-4 bg-gray300 dark:bg-gray1000 dark:border-gray800 border border-gray400 my-2 w-full gap-2">
          <SelectInput
            label="Country"
            placeholder="Country"
            onChange={(text: any) =>
              setFieldValue("country", text.target.value)
            }
            data={[
              {
                id: "Nigeria",
                value: "Nigeria",
                label: "Nigeria",
              },
            ]}
            value={formfields.country}
          />
          <SelectInput
            label="Bank"
            placeholder="Select bank"
            onChange={(text: any) =>
              setFieldValue("bank_code", text.target.value)
            }
            data={allBanks?.map((item) => {
              // @ts-ignore
              return { id: item.code, value: item.code, label: item.name };
            })}
            value={formfields.bank_code}
          />

          {userInfo?.account_name && userInfo?.account_number ? (
            <div className="flex flex-col gap-3">
              <TextInput
                label="Account number*"
                placeholder="Account number"
                onChange={(e) => console.log("")}
                type="number"
                required
                value={formfields.account_number}
                className="lg:w-full"
                readOnly
              />

              <TextInput
                label="Account name*"
                placeholder="Account name"
                onChange={(e) => console.log("")}
                type="text"
                required
                value={formfields.account_name}
                errorMsg={errmsg}
                readOnly
                className="lg:w-full"
              />
            </div>
          ) : (
            <div className="flex flex-col gap-3">
              <TextInput
                label="Account number*"
                placeholder="Account number"
                onChange={(e) =>
                  setFieldValue("account_number", e.target.value)
                }
                type="number"
                required
                value={formfields.account_number}
                className="lg:w-full"
              />
              <TextInput
                label="Account name*"
                placeholder="Account name"
                onChange={(e) => console.log("")}
                type="text"
                required
                value={formfields.account_name}
                errorMsg={errmsg}
                readOnly
                className="lg:w-full"
              />
            </div>
          )}
        </div>
      </div>

      <div className="w-full ">
        {userInfo.account_name && userInfo.account_number ? (
          <div className="w-full flex items-center justify-center gap-2 py-4">
            <button
              onClick={() => setOpenMsg(true)}
              className={`flex cursor-pointer text-xs font-display font-semibold p-2 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              Request for change of bank details
            </button>
          </div>
        ) : (
          <div className="w-full flex items-center justify-center gap-2 py-4">
            <button
              onClick={() => console.log("cancle")}
              className="flex text-xs font-display font-semibold cursor-pointer p-2 rounded-full shadow bg-gray500 items-center text-gray1000 transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100"
            >
              Cancel
            </button>
            <button
              onClick={saveBankDetails}
              disabled={
                isLoading || !formfields.account_name || !formfields.bank_code
              }
              className={`flex cursor-pointer text-xs font-display font-semibold p-2 rounded-full shadow ${
                isLoading || !formfields.account_name || !formfields.bank_code
                  ? "bg-primary300"
                  : "bg-primary500"
              }  items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              {isLoading ? (
                <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
              ) : (
                "Save"
              )}
            </button>
          </div>
        )}
      </div>
      <Modal
        open={openMsg}
        onClose={() => {
          setOpenMsg(false);
        }}
        className="lg:w-1/3"
      >
        <div className="p-4 rounded-xl w-full">
          <p className="text-base font-semibold text-gray1000 dark:text-gray200 mb-3">
            Looking to change your bank?
          </p>
          <p className="text-base font-light text-gray1000 dark:text-gray200 mb-3">
            For security reasons, please contact EAD Shortlets support
            08170700011 08170400011 or hello@eadshortlets.com to change your
            bank details.
          </p>
          <div className="w-full flex justify-center">
            <button
              onClick={() => setOpenMsg(false)}
              className={`flex cursor-pointer text-xs font-display font-semibold p-2 px-4 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
            >
              okay
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PaymentInfo;
