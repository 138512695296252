import { useState } from "react";
import Uplaoding from "../../../../assets/images/uploadimg.png";
import Multiing from "../../../../assets/images/multiimg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { uploadImagesMultiple } from "../../../../redux/actions/property";
import NavigateButton from "./NavigateButton";
import { paths } from "../../../../routes/paths";
import { usePropertyForm } from "../hooks/use-property-form";
import { FaPlus, FaX } from "react-icons/fa6";
import { showToast } from "../../../../utils/helper-functions";

const UplaodImage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setPropertyFieldValue, propertyFormValue } = usePropertyForm();
  const [imgIndex, setImgindex] = useState<number>(0);
  const [file, setFile] = useState<string[]>(
    propertyFormValue.media_urls || []
  );

  const uploadFile = async (e: any) => {
    const files = Array.from(e.target.files) as any[];
    if (files.length > 5) {
      showToast(
        "Please you can only select a maximum of 5 images",
        "error"
      );
      return;
    }

    const fd = new FormData();
    files.forEach((image: Blob) => {
      fd.append(`media`, image, "media");
    });

    console.log("filesss", files);
    // @ts-ignore
    const res = (await dispatch(uploadImagesMultiple(fd))) as string[];
    if (res) {
      console.log("ressss", res);
      setFile([...file, ...res]);
    }
    return;
  };

  const removeImage = (id: string) => {
    if (file.includes(id)) {
      const newImages = file.filter((img) => img !== id);
      setFile(newImages);
      setImgindex(0);
    }
  };

  return (
    <div className="flex flex-col items-center overflow-scroll no-scrollbar">
      {file.length === 0 && (
        <div className="container">
          <p className="font-display text-xl lg:text-2xl font-bold text-gray1000 dark:text-gray200 lg:mx-8 lg:text-center xl:text-center mb-2">
            Take cool photos of this property
          </p>
          <label
            htmlFor="file_input"
            className="w-full cursor-pointer flex flex-col items-center border rounded-lg border-dashed border-gray600 py-8 my-4"
          >
            <input
              type="file"
              multiple
              aria-describedby="file_input_help"
              id="file_input"
              accept="image/*"
              name="media_urls"
              onChange={uploadFile}
              // @ts-ignore
              // onClick={(event) => event.target?.removeAttribute("capture")} // Explicitly remove capture attribute
              hidden
            />
            <img src={Uplaoding} alt="" className="my-1" />
            <p className="text-xs font-display font-bold text-primary500">
              Click to upload{" "}
              {/* <span className="text-xs font-medium font-display text-gray900 dark:text-gray200">
                or drag to drop
              </span> */}
            </p>
            <p className="text-xs font-display text-gray900 font-medium dark:text-gray200">
              Choose at least 5 photos
            </p>
          </label>
        </div>
      )}
      {file.length > 0 && (
        <div className="w-full">
          <div className="flex items-center justify-between w-full gap-2">
            <p className="font-display text-lg font-bold text-gray1000 dark:text-gray200 ">
              Take cool photos of this property
            </p>
            <label
              htmlFor="file_input"
              className="px-3 p-1 gap-2 text-nowrap rounded-full bg-gray500 dark:bg-gray800 flex items-center lg:text-sm text-xs cursor-pointer dark:text-gray200 font-semibold"
            >
              <input
                type="file"
                aria-describedby="file_input_help"
                id="file_input"
                multiple
                accept="image/*"
                name="media_urls"
                onChange={uploadFile}
                hidden
              />
              <FaPlus />
              Add more
            </label>
          </div>

          <div className="w-full flex flex-col items-center border rounded-lg border-dashed border-gray600 my-4">
            <img
              src={file[imgIndex] || Multiing}
              className="place-self-center w-full h-56 object-fill"
              alt=""
            />
          </div>
          <div className="flex flex-wrap items-center gap-2 w-full">
            <label
              htmlFor="file_input"
              className="flex-col border border-dashed rounded-lg lg:w-40 w-20 flex justify-center items-center border-gray600 p-2"
            >
              <img src={Multiing} className="lg:w-14 w-6" alt="" />
              <p className="text-xs font-display font-bold text-primary500 text-center">
                Click to upload
              </p>
            </label>
            {file.map((item, index) => (
              <div
                className="cursor-pointer border border-dashed rounded-lg lg:w-40 w-20 flex justify-center border-gray600 relative"
                onClick={() => setImgindex(index)}
              >
                <img src={item || Multiing} className="w-full h-20" alt="" />
                <button
                  className="bg-primary500 hover:bg-primary400 transition-all duration-200 ease-in-out text-white rounded-full h-5 p-1 absolute top-0 right-0"
                  onClick={() => removeImage(item)}
                >
                  <FaX className="text-xs" />
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* navigation */}
      <NavigateButton
        onBack={() => navigate(-1)}
        onNext={() => {
          setPropertyFieldValue({
            ...propertyFormValue,
            media_urls: file,
          });
          navigate(`${paths.HOST_LANDING}/property-title`);
        }}
        disable={file.length < 5}
      />
    </div>
  );
};

export default UplaodImage;
