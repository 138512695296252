import { useCallback, useEffect, useState } from "react";
import apiClient from "../network/axios-client";
import { urls } from "../network";
import { SingleBookingType } from "../redux/types";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentUser } from "../redux/reducers/user-slice";
import { useNavigate } from "react-router-dom";
import { paths } from "../routes/paths";
import Auth from "../middleware/storage";
import {
  dashboardStatsApi,
  fetchHostBookings,
  validatePropertyBooking,
} from "../redux/actions/property";
import { downloadFile, showToast } from "../utils/helper-functions";

interface Prop {
  checkinDate: Date;
  checkoutDate: Date;
  countAdult: number;
  countChildren: number;
  propertyId: number;
  bookingId?: string | number | undefined | null;
}

export const useBookingSummary = ({
  checkinDate,
  checkoutDate,
  countAdult,
  countChildren,
  propertyId,
}: Prop) => {
  const navigate = useNavigate();
  const [bookingSummary, setBookingSummary] = useState<{
    nights?: number;
    basePrice?: number;
    serviceFee?: number;
    totalAmount?: number;
  }>();
  const [bookingSummaryErrMsg, setBookingSummaryErrMsg] = useState<string>("");
  const [isLoadingBookSummary, setIsLoadingBooksummary] = useState(false);
  const [bookingInfo, setBookingInfo] = useState<any>();
  const profile = useSelector(getCurrentUser);
  const [isInitiateLoading, setInitiateIsLoading] = useState(false);

  const fetchBookingSummary = useCallback(async () => {
    setIsLoadingBooksummary(true);
    try {
      const res = await apiClient.post(urls.BookingSummary, {
        property_id: propertyId,
        check_in_date: checkinDate?.toISOString(),
        check_out_date: checkoutDate?.toISOString(),
        number_of_adults: countAdult,
        number_of_children: countChildren,
      });
      setIsLoadingBooksummary(false);
      if (res.data.hasError) {
        setBookingSummaryErrMsg(res.data.errors.message);
        setBookingSummary(undefined);
        setBookingInfo(undefined);
      } else {
        setBookingSummaryErrMsg("");
        setBookingSummary({
          nights: res.data.data.nights,
          basePrice: res.data.data?.basePrice,
          totalAmount: res.data.data?.totalAmount,
          serviceFee: res.data.data?.serviceFee,
        });
        setBookingInfo(res.data.data);
      }
    } catch (error) {}

    // eslint-disable-next-line
  }, [checkinDate, checkoutDate, countAdult, countChildren, propertyId]);

  const initiateBooking = useCallback(async () => {
    try {
      if (Auth.isAuthenticated()) {
        const payload = {
          save_card: false,
          booking_type: "self",
          property_id: propertyId,
          check_in_date: checkinDate,
          check_out_date: checkoutDate,
          number_of_adults: countAdult,
          number_of_children: countChildren,
        };
        setInitiateIsLoading(true);
        const res = await apiClient.post(urls.BookingInitiate, payload);
        if (res.data.hasError) {
          setBookingSummaryErrMsg(res.data.errors.message);
        } else {
          setBookingSummaryErrMsg("");
          const { booking } = res.data.data;
          navigate(`${paths.BOOKING}/${booking.id}`);
        }
      } else {
        navigate(paths.SIGNIN, { state: { shouldGoback: true } });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setInitiateIsLoading(false);
    }

    // eslint-disable-next-line
  }, [
    checkinDate,
    checkoutDate,
    countAdult,
    countChildren,
    propertyId,
    profile,
  ]);

  const editbookingApi = async ({
    bookingId,
    comment,
    booking_type,
    guestInfo,
  }: {
    bookingId: number;
    comment: string | null;
    booking_type: string;
    guestInfo?: {
      first_name: string;
      last_name: string;
      email: string;
      phone: string;
    };
  }) => {
    try {
      const res = await apiClient.post(urls.EditBooking, {
        booking_id: bookingId,
        number_of_adults: countAdult,
        number_of_children: countChildren,
        check_in_date: checkinDate,
        check_out_date: checkoutDate,
        guest_info: guestInfo,
        comment: comment,
        booking_type: booking_type,
      });
      setIsLoadingBooksummary(false);
      if (res.data.hasError) {
        setBookingSummaryErrMsg(res.data.errors.message);
        return false;
      } else {
        setBookingSummaryErrMsg("");
        showToast("Success", "success");
        return true;
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (
      checkinDate?.toISOString() &&
      checkoutDate?.toISOString() &&
      propertyId
    ) {
      fetchBookingSummary();
    }
    // eslint-disable-next-line
  }, [checkinDate, checkoutDate, countAdult, countChildren, propertyId]);

  return {
    bookingSummary,
    isLoadingBookSummary,
    bookingSummaryErrMsg,
    bookingInfo,
    initiateBooking,
    isInitiateLoading,
    editbookingApi,
  };
};

export const useBookingInfo = ({
  bookingId,
}: {
  bookingId: string | number;
}) => {
  const dispatch = useDispatch();
  const [singleBookingInfo, setSingleBookingInfo] =
    useState<SingleBookingType | null>(null);
  const [isLoadingBookinginfo, setIsLoadingBookingInfo] = useState(false);
  const [bookingInfoErrMsg, setBookingInfoErrMsg] = useState<string>("");

  const fetchSingleBookingInfo = async (id: any) => {
    try {
      setIsLoadingBookingInfo(true);
      const res = await apiClient.get(urls.BookingSingleInfo(id));
      setIsLoadingBookingInfo(false);
      if (res.data.hasError) {
        setBookingInfoErrMsg(res.data.errors.message);
      } else {
        setBookingInfoErrMsg("");
        setSingleBookingInfo(res.data.data);
      }
    } catch (error) {
      setIsLoadingBookingInfo(false);
      console.log(error);
    }
  };

  const approveBooking = async () => {
    setIsLoadingBookingInfo(true);
    const res = await dispatch(
      // @ts-ignore
      validatePropertyBooking({
        booking_id: Number(bookingId),
        decision: "accept",
      })
    );
    setIsLoadingBookingInfo(false);
    if (res) {
      fetchSingleBookingInfo(bookingId);
      //@ts-ignore
      dispatch(fetchHostBookings());
      // @ts-ignore
      dispatch(dashboardStatsApi());
    }
  };

  const declineBooking = async (reason?: string) => {
    setIsLoadingBookingInfo(true);
    const res = await dispatch(
      // @ts-ignore
      validatePropertyBooking({
        booking_id: Number(bookingId),
        decision: "decline",
        reason: reason,
      })
    );
    setIsLoadingBookingInfo(false);
    if (res) {
      fetchSingleBookingInfo(bookingId);
      //@ts-ignore
      dispatch(fetchHostBookings());
      // @ts-ignore
      dispatch(dashboardStatsApi());
    }
  };

  const downloadReceiptBooking = async () => {
    try {
      const res = await apiClient.get(urls.ViewReciept(bookingId));
      if (res.data.hasError) {
        setBookingInfoErrMsg(res.data.errors.message);
      } else {
        setBookingInfoErrMsg("");
        downloadFile(res.data.data?.properties_pdf, "receipt.pdf");
      }
    } catch (error) {
      setIsLoadingBookingInfo(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (bookingId) {
      fetchSingleBookingInfo(bookingId);
    }
    // eslint-disable-next-line
  }, [bookingId]);

  return {
    isLoadingBookinginfo,
    bookingInfoErrMsg,
    singleBookingInfo,
    fetchSingleBookingInfo,
    approveBooking,
    declineBooking,
    downloadReceiptBooking,
  };
};
