import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import OTPInput from "react-otp-input";
import { getCurrentUser } from "../../../../redux/reducers/user-slice";
import { getVerificationChecklist } from "../../../../redux/reducers/property-slice";
import moment from "moment";
import { paths } from "../../../../routes/paths";
import { useNavigate } from "react-router-dom";
import {
  updateProperty,
  uploadDocsSingle,
  viewChecklist,
} from "../../../../redux/actions/property";
import Modal from "../../../../components/custom/modal";
import BookInspection from "../../host-onboard/components/BookInspection";
import { getIsLoading } from "../../../../redux/reducers/loader-slice";
import { CategoryProp } from "../../../../redux/types";
import DropdownTwo from "../../../../components/custom/drop-down-two";
import {
  fetchUser,
  updateUserDocument,
} from "../../../../redux/actions/authuser";
import UploadFiles from "../../../../components/uploadFiles";
import {
  PhoneInputField,
  TextInput,
} from "../../../../components/custom/text-input";
import { useTimer } from "../../../../hooks/use-timer";
import { usePhoneVerify } from "../../../../hooks/use-verification";
import { IoDocumentAttach } from "react-icons/io5";

const VerifyCheckListItem = ({
  title,
  desc,
  btnTitle,
  action,
  status,
}: {
  title: string;
  desc: string;
  btnTitle: string;
  action: () => void;
  status: string;
}) => (
  <div className="p-2 border border-gray400 dark:border-gray800 rounded-xl my-2">
    <div className="flex gap-2 items-center pb-2">
      <p className="text-sm font-semibold text-gray1000 dark:text-gray200">
        {title}
      </p>
      <p className="text-xs bg-gray400 rounded-full px-[4px] py-[2px]">
        {status}
      </p>
    </div>
    <p className="text-sm font-light text-gray1000 dark:text-gray200 pb-2">
      {desc}
    </p>
    <button
      onClick={action}
      className="w-fit justify-center items-center rounded-full p-1.5 bg-gray400 dark:bg-gray800 my-2 px-2"
    >
      <span className="text-sm text-gray1000 dark:text-gray200 font-semibold">
        {btnTitle}
      </span>
    </button>
  </div>
);

type ValuePiece = Date | null;

const IncompleteVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const profile = useSelector(getCurrentUser);
  const isLoading = useSelector(getIsLoading);
  const verificationStatus = useSelector(getVerificationChecklist);
  const [open, setOpen] = useState<boolean>(false);
  const [openPhoneVerify, setPhoneVerifyOpen] = useState<boolean>(false);
  const [value, onChange] = useState<ValuePiece>(new Date());
  const [propertyId, setPropertyId] = useState(0);
  const [uploadKey, setUploadKey] = useState(false);
  const [docurl, setDocUrl] = useState<string | null>(null);
  const [errorMsg, setErroMsg] = useState("");
  const [docType, setDoctype] = useState<string>("");
  const [docNo, setDocNo] = useState<string>("");
  const [phone, setPhone] = useState<string>(profile?.phone || "");
  const [otp, setOtp] = useState("");
  const propertyCategories = JSON.parse(
    localStorage.getItem("allcategories") || "{}"
  ) as CategoryProp;
  const currentProfile = localStorage.getItem("USERTYPE");
  const onBookInspection = async () => {
    // @ts-ignore
    const res = await dispatch(
      // @ts-ignore
      updateProperty({ id: propertyId, inspection_date: value?.toISOString() })
    );
    if (res) {
      setOpen(false);
      // @ts-ignore
      dispatch(viewChecklist());
    }
  };

  const handleDocUploadClick = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/jpeg,image/gif,image/png,application/pdf";
    fileInput.click();
    const fd = new FormData();
    setDocUrl("");
    fileInput.onchange = async (event: any) => {
      fd.append("media", event.target.files[0], "media");
      // @ts-ignore
      const res = (await dispatch(uploadDocsSingle(fd))) as string;
      if (res) {
        setDocUrl(res);
        // setDocfile((prevUploadedFiles) => [...prevUploadedFiles, res]);
      }
      return;
    };
  };

  const saveUpload = async () => {
    if (docType.length < 1) {
      setErroMsg("Kindly select a document type");
      return;
    }
    if (!docurl) {
      setErroMsg("Kindly select a document image or a profile picture");
      return;
    }
    const res = await dispatch(
      // @ts-ignore
      updateUserDocument({
        document_type: docType,
        document_image_url: docurl,
        document_number: docNo,
        user_selfie_url: null,
      })
    );

    if (res) {
      setUploadKey(false);
      // setOpen(false);
      // setImageUrl(null);
      // @ts-ignore
      dispatch(fetchUser());
      // @ts-ignore
      dispatch(viewChecklist());
    }
  };

  const { minutes, seconds, restartTimer, clearTimer } = useTimer(300);
  const {
    isPhoneVerifyLoading,
    verifyPhone,
    validatePhone,
    validateOtp,
    setValidateOtp,
  } = usePhoneVerify();

  useEffect(() => {
    if (minutes === "00" && seconds === "00") {
      clearTimer();
    }
    // eslint-disable-next-line
  }, [seconds, minutes]);

  console.log('verificationStatus.user.is_bank_verified',verificationStatus.user)

  return (
    <div>
      {uploadKey ? (
        <div className="flex flex-col gap-4">
          <div>
            <p className="text-xs text-primary500">{errorMsg}</p>
            <DropdownTwo
              label="Document type*"
              // @ts-ignore
              options={
                propertyCategories.userKycDocumentTypes.map((item) => {
                  return { value: item, label: item };
                }) || []
              }
              onSelect={(e) => {
                setDoctype(e);
                setErroMsg("");
              }}
              value={docType || "Select"}
            />
          </div>
          <TextInput
            label="Document no*"
            placeholder="Document no"
            onChange={(e: any) => setDocNo(e.target.value)}
            type="number"
            required
            value={docNo}
            className=""
          />
          <div className="flex items-center justify-between">
            <div className="w-full">
              {docurl ? (
                <div>
                  <IoDocumentAttach className="text-primary400 text-4xl" />
                  <p className="py-2 text-primary500 text-base">
                    Document file attached for upload
                  </p>
                </div>
              ) : (
                <UploadFiles
                  onClick={handleDocUploadClick}
                  loader={isLoading}
                />
              )}
            </div>
            {/* <div className="w-full h-40">
              {picture ? (
                <img src={picture} className="w-full object-cover h-40" alt="" />
              ) : (
                <Upload onClick={handleUploadClick} loader={isLoading} />
              )}
            </div> */}
          </div>

          <button
            onClick={saveUpload}
            disabled={!docType || !docNo || !docurl}
            className={`text-xs flex items-center gap-2 font-bold text-eadWhite place-self-center ${
              !docType || !docNo || !docurl || isLoading ? "bg-primary300" : "bg-primary500"
            } rounded-full p-2 w-fit place-self-end flex-shrink-0`}
          >
            {isLoading ? "uplaoding" : "upload"}
          </button>
        </div>
      ) : (
        <div className="flex flex-col gap-3">
          {!verificationStatus.user.is_email_verified && (
            <VerifyCheckListItem
              title={"Email"}
              desc={`Please verify your email address by clicking the link in the
                   message we just sent to: ${profile?.email}`}
              btnTitle={"Verify verification email"}
              status="unverified"
              action={() => console.log("email")}
            />
          )}
          {!verificationStatus.user.is_phone_verified && (
            <VerifyCheckListItem
              title={"Phone number"}
              desc={`Please verify your phone number by clicking the link in the
                   message we just sent`}
              btnTitle={"Verify phone number"}
              status="unverified"
              action={() => {
                setPhone(profile?.phone);
                setPhoneVerifyOpen(true);
              }}
            />
          )}
          {!verificationStatus.user.is_bank_verified &&
            currentProfile === "HOST" && (
              <VerifyCheckListItem
                title={"Bank Details"}
                desc={`Please add your bank account details from your profile settings in the payment method section`}
                btnTitle={"Add bank details"}
                status="unverified"
                action={() => navigate(`${paths.GUEST}/settings?tab=Payment`)}
              />
            )}
          {(!verificationStatus.user.kyc_document_url ||
            verificationStatus.user.verification_status !== "verified") && (
            <VerifyCheckListItem
              title={"Document Verification"}
              desc={`Please upload your documents for ID verification. Eg: Passport, Driving License`}
              btnTitle={"Upload document"}
              status={verificationStatus.user.verification_status}
              action={() => setUploadKey(true)}
            />
          )}
          {currentProfile === "HOST" &&
            verificationStatus?.user_properties.map((item, index) => {
              if (!item.is_inspected)
                return (
                  <VerifyCheckListItem
                    title={"Property inspection"}
                    desc={`${item?.name} on ${moment(
                      item?.inspection_date
                    ).format("D MMMM YYYY")}`}
                    btnTitle={"Reschedule"}
                    status={item.verification_status}
                    action={() => {
                      setOpen(true);
                      setPropertyId(item.id);
                    }}
                  />
                );

              return <></>;
            })}

          <Modal
            open={open}
            onClose={() => {
              setOpen(false);
            }}
          >
            <BookInspection
              onCancel={() => {
                setOpen(false);
              }}
              onConfirm={onBookInspection}
              onChange={onChange}
              value={value}
            />
          </Modal>
          <Modal
            open={openPhoneVerify}
            onClose={() => {
              setPhoneVerifyOpen(false);
              setValidateOtp(false);
              setOtp("");
            }}
          >
            <div className="p-4 rounded-xl w-full">
              <p className="text-base font-semibold text-gray1000 dark:text-gray200 mb-3">
                {validateOtp
                  ? "Phone Verification"
                  : "Verify your phone number"}
              </p>
              <p className="text-xs font-light text-gray1000 dark:text-gray200 mb-3">
                {validateOtp
                  ? `Kindly enter the OTP sent to ${phone}`
                  : "Confirm or edit your phone number, then proceed to verify"}
              </p>
              {validateOtp ? (
                <div>
                  <p className="text-xs font-semibold text-gray1000 dark:text-gray200 my-2">
                    Enter OTP
                  </p>
                  <div className="w-full flex flex-col items-center justify-center gap-2">
                    <div className="lg:block 2xl:block hidden">
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputStyle={{
                          borderWidth: 1,
                          borderRadius: 10,
                          width: 60,
                          height: 60,
                          margin: 5,
                          fontSize: 16,
                          borderColor: "#e2e2e2",
                        }}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <div className="lg:hidden 2xl:hidden block">
                      <OTPInput
                        value={otp}
                        onChange={setOtp}
                        numInputs={6}
                        inputStyle={{
                          borderWidth: 1,
                          borderRadius: 10,
                          width: 45,
                          height: 45,
                          margin: 5,
                          fontSize: 16,
                          borderColor: "#e2e2e2",
                          color: "#000",
                        }}
                        renderInput={(props) => <input {...props} />}
                      />
                    </div>
                    <p className="text-center text-xs">
                      Expires ({`${minutes}:${seconds}`})
                    </p>
                    {minutes === "00" && seconds === "00" && (
                      <button
                        onClick={async () => {
                          await verifyPhone({ phone });
                          restartTimer();
                        }}
                        className="text-sm text-primary500 my-1"
                      >
                        Resend code
                      </button>
                    )}
                  </div>
                </div>
              ) : (
                <div className="bg-gray300 p-2 rounded-xl border dark:bg-gray1000 dark:border-gray800">
                  <PhoneInputField
                    label="Phone number*"
                    value={phone}
                    onChange={(phone: any) => setPhone(phone)}
                    inputProps={{
                      name: "phone",
                    }}
                    className="lg:w-full dark:text-gray1000"
                  />
                </div>
              )}
              {validateOtp ? (
                <div className="w-full flex items-center gap-2 justify-center mt-5 mb-3">
                  <button
                    onClick={() => {
                      setPhoneVerifyOpen(false);
                      setValidateOtp(false);
                    }}
                    className={`w-1/3 flex text-xs justify-center font-display font-semibold p-2 rounded-full shadow bg-gray400 dark:text-gray1000 items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={async () => {
                      const res = await validatePhone({
                        phone: phone,
                        code: otp,
                      });
                      if (res) {
                        setPhoneVerifyOpen(false);
                        setOtp("");
                        setValidateOtp(false);
                        // @ts-ignore
                        dispatch(viewChecklist());
                      }
                    }}
                    className={`w-1/3 flex text-xs gap-2 justify-center font-display font-semibold p-2 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
                  >
                    {isPhoneVerifyLoading && (
                      <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
                    )}
                    Submit
                  </button>
                </div>
              ) : (
                <div className="w-full flex items-center gap-2 justify-center mt-5 mb-3">
                  <button
                    onClick={() => setPhoneVerifyOpen(false)}
                    className={`w-1/3 flex text-xs justify-center font-display font-semibold p-2 rounded-full shadow bg-gray400 dark:text-gray1000 items-center transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
                  >
                    Cancel
                  </button>
                  <button
                    onClick={async () => {
                      await verifyPhone({ phone });
                      restartTimer();
                    }}
                    className={`w-1/3 flex text-xs gap-2 justify-center font-display font-semibold p-2 rounded-full shadow bg-primary500 items-center text-eadWhite transition duration-500 ease-in-out transform hover:-translate-y-1 hover:scale-100`}
                  >
                    {isPhoneVerifyLoading && (
                      <div className="border-eadWhite h-4 w-4 animate-spin rounded-full border-2 border-t-primary500" />
                    )}
                    Verify
                  </button>
                </div>
              )}
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default IncompleteVerification;
